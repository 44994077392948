import React from "react";
import "../styles/stepper.css";
import work from "../data/Work";
import grad from "../data/Edu";
import skills from "../data/Language";
import hobby from "../data/Interests";
import {
	ExperienceWrapper,
	WorkWrapper,
	EdWrapper,
	EdContent,
	Stepper,
	StepperHead,
	LogoLink,
	StepperDesc,
} from "../styles/workStyle.js";
import Layout from "../components/layout";

const Experience = () => (
	<Layout>
		<ExperienceWrapper>
			<WorkWrapper>
				<h1>Work Experience</h1>
				<div className="experience-stepper">
					{work.map((item) => (
						<div key={item.id} className="step">
							<div>
								<div className="circle" />
								<div className="line" />
							</div>
							<Stepper>
								<StepperHead>
									<div>
										<h3>{item.designation}</h3>
										<a
											href={item.companyLink}
											target="_blank"
											rel="noreferrer"
										>
											<b>{item.name}</b>
										</a>
										<p>{item.dated}</p>
									</div>
									<LogoLink href={item.companyLink}>
									</LogoLink>
								</StepperHead>
								<StepperDesc
									dangerouslySetInnerHTML={{
										__html: item.description,
									}}
								/>
							</Stepper>
						</div>
					))}
				</div>
			</WorkWrapper>

			<EdWrapper>
				<h1>Education</h1>
				<EdContent>
					{grad.map((item) => (
						<div key={item.id}>
							<p>
								<b>{item.name}</b>
								<p>B.S in {item.major}</p>
								{item.dated}
							</p>

							<p>
								<b>Google Data Analytics Certification,</b> Coursera
								<br></br>2021
							</p>
						</div>
					))}
				</EdContent>
				<h1>Skills</h1>
				<EdContent>
					<p><b>Programming Languages:</b><ul><li>Python</li> <li>SQL</li> <li>R</li> <li>C/C++</li> <li>Java</li> <li>HTML</li> <li>JavaScript</li> <li>Typescript</li></ul>
						<br/>
						<b>Tools:</b> <ul><li>Tableau</li> <li>React Native</li> <li>Angular</li> <li>Ionic</li> <li>MS Office Suite</li> <li>Git</li> <li>Figma</li> <li>Adobe XD</li> <li>Salesforce</li></ul></p>
				</EdContent>
			</EdWrapper>
		</ExperienceWrapper>
	</Layout>
);

export default Experience;
